import React from "react"
import { graphql } from "gatsby"
import {Col, Container, Row} from "react-bootstrap"
import {IPage} from "../types";
import {StaticImage} from "gatsby-plugin-image";
import Page from "gatsby-theme-nieuwbouw/src/components/Page";

interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    return (
        <Page pageTitle={data.file.childMarkdownRemark.frontmatter.title} pageDescription={data.file.childMarkdownRemark.frontmatter.description}>
            <StaticImage src={'../img/extra/GettyImages-514270989@2x.png'} alt={'water'} />
            <Container
                fluid={'lg'}
                as={'section'}
                className={"my-5 text-center text-md-left"}
            >
                <span className={'title text-uppercase font-weight-bold'}>Extra</span>
                <h2>
                    De <span className={'text-primary'}>toekomst</span> is nu
                </h2>
                <p className={'lead my-5'} style={{
                    maxWidth: 768
                }}>
                    Bij het ontwerp van woonconcept Vaartsche Weelde worden er op het gebied van duurzaamheid geen concessies gedaan. Een overzicht van prestaties en innovaties.
                </p>
                <Row>
                    <Col md={{span: 6, offset: 1}}>
                        <section>
                           <header>
                               <h6>
                                   {data.duurzaam.childMarkdownRemark.frontmatter.title}
                               </h6>
                           </header>
                            <div
                                dangerouslySetInnerHTML={{__html: data.duurzaam.childMarkdownRemark.html}}
                            />
                        </section>
                        <section>
                            <header>
                                <h6>
                                    {data.natuur.childMarkdownRemark.frontmatter.title}
                                </h6>
                            </header>
                            <div
                                dangerouslySetInnerHTML={{__html: data.natuur.childMarkdownRemark.html}}
                            />
                        </section>
                    </Col>
                    <Col
                        md={5}
                        className={'align-self-end'}
                    >
                        <StaticImage
                            alt={"vogel"}
                            src={"../img/extra/7c8c4ec0-7f3a-49bc-bb5e-7c1e2f4e6094.jpg&h=630&w=1000&mode=crop&v=1512056498@2x.png"} />
                        <StaticImage
                            alt={"natuursteen"}
                            src={"../img/extra/unnamed@2x.png"} />
                    </Col>
                </Row>
                <Row as={'section'} className={'my-5'}>
                    <Col md={{span: 6, order: 2}}>
                        <h6>{data.verwarming.childMarkdownRemark.frontmatter.title}</h6>
                        <div
                            dangerouslySetInnerHTML={{__html: data.verwarming.childMarkdownRemark.html}} />
                    </Col>
                    <Col md={6}>
                            <StaticImage
                                alt={"stal"}
                                src={"../img/extra/spa-bathroom-in-luxurious-modern-villa-with-huge-natural-rock-wall-picture-id1253547527@2x.png"} />
                    </Col>
                </Row>
                <Row as={'section'} className={'my-5'}>
                    <Col md={6}>
                        <h6>{data.mobiliteit.childMarkdownRemark.frontmatter.title}</h6>
                        <div
                            dangerouslySetInnerHTML={{__html: data.mobiliteit.childMarkdownRemark.html}} />
                    </Col>
                    <Col md={6}>
                        <StaticImage
                            alt={"mobiliteit"}
                            src={"../img/extra/GettyImages-1146607789@2x.png"} />
                    </Col>
                </Row>
                <Row as={'section'} className={'my-5'}>
                    <Col md={{span: 6, order: 2}}>
                        <h6>{data.groen.childMarkdownRemark.frontmatter.title}</h6>
                        <div
                            dangerouslySetInnerHTML={{__html: data.groen.childMarkdownRemark.html}} />
                    </Col>
                    <Col md={6}>
                        <StaticImage
                            alt={"groen"}
                            src={"../img/extra/GettyImages-1174001922 (1)@2x.png"} />
                    </Col>
                </Row>
                <Row as={'section'} className={'my-5'}>
                    <Col md={6}>
                        <h6>{data.keuken.childMarkdownRemark.frontmatter.title}</h6>
                        <div
                            dangerouslySetInnerHTML={{__html: data.keuken.childMarkdownRemark.html}} />
                    </Col>
                    <Col md={6}>
                        <StaticImage
                            alt={"keuken"}
                            src={"../img/extra/GettyImages-1217234710@2x.png"} />
                    </Col>
                </Row>
                <Row as={'section'} className={'my-5'}>
                    <Col md={{span: 6, order: 2}}>
                        <h6>{data.badkamer.childMarkdownRemark.frontmatter.title}</h6>
                        <div
                            dangerouslySetInnerHTML={{__html: data.badkamer.childMarkdownRemark.html}} />
                    </Col>
                    <Col md={6}>
                        <StaticImage
                            alt={"groen"}
                            src={"../img/extra/GettyImages-1198988779@2x.png"} />
                    </Col>
                </Row>
            </Container>
        </Page>
    );
}

interface PageQueryData {
    file: {
        childMarkdownRemark: IPage
    }
    keuken: {
        childMarkdownRemark: IPage
    }
    badkamer: {
        childMarkdownRemark: IPage
    }
    duurzaam: {
        childMarkdownRemark: IPage
    }
    groen: {
        childMarkdownRemark: IPage
    }
    mobiliteit: {
        childMarkdownRemark: IPage
    }
    natuur: {
        childMarkdownRemark: IPage
    }
    verwarming: {
        childMarkdownRemark: IPage
    }
}

export const pageQuery = graphql`
    query {
        file(name: {eq: "extra"}) {
            childMarkdownRemark {
                ...Page
            }
        }
        keuken: file(name: {eq: "keuken"}) {
            childMarkdownRemark {
                ...Page
            }
        }
        badkamer: file(name: {eq: "badkamer"}) {
            childMarkdownRemark {
                ...Page
            }
        }
        duurzaam: file(name: {eq: "duurzaam"}) {
            childMarkdownRemark {
                ...Page
            }
        }
        groen: file(name: {eq: "groen"}) {
            childMarkdownRemark {
                ...Page
            }
        }
        mobiliteit: file(name: {eq: "mobiliteit"}) {
            childMarkdownRemark {
                ...Page
            }
        }
        natuur: file(name: {eq: "natuur"}) {
            childMarkdownRemark {
                ...Page
            }
        }
        verwarming: file(name: {eq: "verwarming"}) {
            childMarkdownRemark {
                ...Page
            }
        }
    }
`
